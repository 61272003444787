import React from 'react'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, FreeMode, Grid } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ExperienceINer_Slider from '../ExperienceINer_Slider/ExperienceINer_Slider';


const Slider = (props) => {

    const breakpoints = {
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
        },
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2, // Adjust for 2 rows
        },
        1024: {
          slidesPerView: 3,
          slidesPerColumn: 2, // Adjust for 2 rows
        },
      };

    return (
        <div className='px-4'>
            <Swiper
                breakpoints={breakpoints}
                spaceBetween={5}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                modules={[Grid, FreeMode, Pagination, Navigation, A11y]}
                className="mySwiper"
            >
                {props.Data.map((elem, index) => {
                    return (
                        <SwiperSlide><ExperienceINer_Slider data={elem} /></SwiperSlide>
                    )
                })}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </Swiper>
        </div>
    )
}

export default Slider
