import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Badge, Image, Card } from 'react-bootstrap'; 


const ExperienceINer_Slider = (props) => {


    return (
        <div className='h-100'>
            <Card className='Custom_Card py-4 h-100'>
                <Card.Img variant="top" src={props.data.icon} style={{height:"5rem"}}/>
                <Card.Body className='Custom_color text-center fs-15'>
                    <Card.Title className='fw-bold'>{props.data.Title}</Card.Title>
                    <Card.Text>
                        {props.data.Description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ExperienceINer_Slider
