import React, { useEffect, useRef } from 'react'
import InnerSlider from './InnerSlider';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import test_image from "../../images/dr6.png";

const HeaderSlider = (props) => {

  let Slider_Content = [
    // {
    //   imgUrl: test_image,
    //   count: "01",
    //   Head1: "About the doctor",
    //   Head2: "Experience",
    //   Head3: "Medical Professional",
    //   order: "12",
    // },
    {
      imgUrl: test_image,
      count: "01",
      Head1: "Dedicated to",
      Head2: "Women's Health",
      Head3: "one patient at a time.",
      order: "12",
    },
    {
      imgUrl: test_image,
      count: "02",
      Head1: "Services",
      Head2: "Healthcare",
      Head3: "Tailored to Your Needs",
      order: "21",
    },
    {
      imgUrl: test_image,
      count: "03",
      Head1: "Patient Testimonials",
      Head2: "Reviews",
      Head3: "Building your Trust",
      order: "12",
    },
    {
      imgUrl: test_image,
      count: "04",
      // Head1: "Contact to",
      Head1: "Schedule an",
      Head2: "Appointment",
      Head3: "Health comes first",
      order: "21",
    }
  ];
  const swiperRef = useRef(null);

  useEffect(() => {
    // Initialize Swiper
    if (swiperRef.current) {
      swiperRef.current.slideTo(props.sliderNumber);
    }
  }, [props.sliderNumber]);


  return (
    <div>
      <Swiper className='SliderHeight'
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={100}
        slidesPerView={1}

        navigation
        loop={true}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={() => {}}
      >
        <SwiperSlide>
          <InnerSlider data={Slider_Content[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <InnerSlider data={Slider_Content[1]} />
        </SwiperSlide>
        <SwiperSlide>
          <InnerSlider data={Slider_Content[2]} />
        </SwiperSlide>
        <SwiperSlide>
          <InnerSlider data={Slider_Content[3]} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default HeaderSlider
