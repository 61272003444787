import { faBootstrap, faFacebook, faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Footer = () => {
  return (

    <div className="container">
      <footer className="pt-5">
        <div className="row">


          <div className="col-6 col-md-4 mb-3 section">
            <h5>Menu</h5>
            <ul className="nav flex-column Options">
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">Main</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link  text-body-secondary">Health Form</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">Pharmacy & Store</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">How it work</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">Pages</a></li>
            </ul>
          </div>

          <div className="col-6 col-md-4 mb-3 section">
            <h5>Expertise</h5>
            <ul className="nav flex-column Options">
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">Obstetrics</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link  text-body-secondary">Gynaecology</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link  text-body-secondary">Laparosopic Surgries</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link text-body-secondary">Operative Hysterscopies</a></li>
            </ul>
          </div>

          <div className="col-6 col-md-4 mb-3 section">
            <h5>Contact</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">Phone: <a href="https://wa.me/+923234868161?text=Hi%20there!%20I%20have%20a%20question." target='_blank'  className="nav-link p-0 text-body-secondary">+92 323 4868161</a></li>
              <li className="nav-item mb-2">Email: <a href="mailto:drfauzia83@gmail.com" target='_blank'  className="nav-link p-0 text-body-secondary">drfauzia83@gmail.com</a></li>
              <li className="nav-item mb-2">Address: <a href="https://maps.app.goo.gl/4isXUAck2HPn1vR99" target='_blank' className="nav-link p-0 text-body-secondary">Hameed Latif Hospital, 14- Abu Bakar Block, New garden town Lahore Pakistan</a></li>
            </ul>
          </div>

          <div className="col-md-5  mb-3 d-none">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>Monthly digest of what's new and exciting from us.</p>
              <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                <label htmlfor="newsletter1" className="visually-hidden">Email address</label>
                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                <button className="btn btn-outline-Subscribe" type="button">Subscribe</button>
              </div>
            </form>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between pt-4  border-top">
          <p>&copy; Project owned by <a href='mailto:usamafaheem80@gmail.com'> Usama Faheem Ahmed </a></p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3"><a className="link-body-emphasis" href="https://www.linkedin.com/in/usama-faheem-ahmed-a1960b1b9/"><FontAwesomeIcon icon={faLinkedin} /> </a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="https://github.com/usamafaheemAhmed"><FontAwesomeIcon icon={faGithub} /> </a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="https://www.instagram.com/usama.faheem.142/"><FontAwesomeIcon icon={faInstagram} /> </a></li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Footer