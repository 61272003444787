import React from 'react';
import { Card, Image } from 'react-bootstrap';
import parse from 'html-react-parser';

const SidebySideBlock = ({ data }) => {

    return (
        <div className={`d-flex px-1 px-md-5 ${data.extra}`}>
            <div className='w-100  w-md-50 p-4 d-flex justify-content-left align-items-center '>
                <div className=''>
                    <h1 className='Custom_color fw-6 fs-25'>{data.position}</h1>
                    <p className='doc_des fw-bold Custom_color2'>{data.at} </p>
                    <p className='doc_des mt-4 text-justify'>
                        {parse(data.para)}
                    </p>

                    <h1 className='Custom_color fw-6 fs-15 mt-3'>{data.servicesTitle}</h1>

                    {data.services && data.services.length > 0 && (
                        <ul className='flex flex-wrap justify-content-start align-items-start'>
                            {data.services.map((elem, index) => (
                                <li key={index}>{elem}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <div className='w-100 w-md-50 '>
                <div className='position-relative d-flex h-100'>
                    <div className='position-absolute top-0 left-0'></div>
                    <div className='w-100 d-flex justify-content-center align-items-center'>
                        <Image src={data.img} alt='image' className='image-fluid w-75' rounded />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebySideBlock;
