
import React, { useState } from 'react';
import { Button, DatePicker, Descriptions, Form, Input, Select, notification } from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { PostAxios } from '../../assets/Globel_Functions/ApiFunction';
import { APIBase } from '../../../Atom';
import { useRecoilValue } from 'recoil';
import axios from 'axios';


const Ap_Form_Ant = (props) => {

  let DefaultBase = useRecoilValue(APIBase);

  let [Data, setData] = useState({});
  let [Items, setItems] = useState([]);

  const layout = {
    labelCol: { span: `auto` },
    wrapperCol: { span: 24 },
    layout: "vertical",
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
      date: '${label} is required',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const countryCodes = [
    { code: '86', name: '+86' },
    { code: '92', name: '+92' },
    // Add more countries as needed
  ];

  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="CountryCode" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        {countryCodes.map(country => (
          <Option key={country.code} value={country.code}>
            {country.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const [form] = Form.useForm();

  const onFinish = (values) => {

    // console.log(props.Data);

    // return false;
    values.SelectedDate = props.Data[0].DateSelected;
    values.SelectedSessions = props.Data[0].SessionSelected;
    console.log(values);


    let Dates_Get = new Date(values.user.DateOfBirth);
    let formattedDate = Dates_Get.getFullYear() + '-' + ('0' + (Dates_Get.getMonth() + 1)).slice(-2) + '-' + ('0' + Dates_Get.getDate()).slice(-2);
    // console.log(formattedDate);
    values.user.DateOfBirth = formattedDate;

    setData(values)

    let Data_Submitted = [
      {
        key: '1',
        label: 'UserName',
        children: values.user.FirstName + values.user.LastName,
      },
      {
        key: '2',
        label: 'Email',
        children: values.user.email,
      },
      {
        key: '4',
        label: 'Gender',
        children: values.user.gender,
      },
      {
        key: '5',
        label: 'Phone',
        children: values.user.phone,
      },
      {
        key: '6',
        label: 'Date Of Birth',
        children: values.user.DateOfBirth,
      },
      {
        key: '7',
        label: 'Session Date',
        children: values.SelectedDate,
      },
      {
        key: '8',
        label: 'Session Time',
        children: values.SelectedSessions,
      },
      {
        key: '9',
        label: '',
        children: '',
      },
      {
        key: '10',
        label: 'introduction To Problem',
        children: values.user.introductionToProblem,
      },
    ]

    setItems(Data_Submitted);
  };

  const ConfirmData = async () => {
    let AxiosOBJ = {
      firstName: Data.user.FirstName,
      lastName: Data.user.LastName,
      email: Data.user.email,
      gender: Data.user.gender,
      phone: Data.user.phone,
      dateOfBirth: Data.user.DateOfBirth,
      problem: Data.user.introductionToProblem,
      sessionTime: Data.SelectedSessions,
      sessionDate: Data.SelectedDate,
    }

    try {
      // Await the result of PostAxios
      const post_result = await PostAxios(`${DefaultBase}/api/user_Appointment/Add`, AxiosOBJ);

      console.log("Post_result", post_result); // This will log the response data from the server
      setItems([])
      openNotificationSuccess('topRight', Data);

    } catch (error) {
      console.log(error);
    }

  }

  const openNotificationSuccess = (placement) => {
    notification.success({
      message: `Your Appointment is Scheduled`,
      description:
        `Miss ${Data.user.FirstName + " " + Data.user.LastName}. We have scheduled appointment for you and at ${Data.SelectedSessions} on ${Data.SelectedDate}. We are looking forward to see you there.`,
      placement,
    });
  };

  return (
    <div>

      {Items.length === 0 &&

        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          initialValues={{ user: { gender: "Female" }, CountryCode: '92' }}
          validateMessages={validateMessages}
          form={form}
        >
          <Button type="text" htmlType='button' className='mb-3' onClick={() => { props.SwitchBack(true) }}> <IoIosArrowBack /> Back </Button>

          <div className='row'>
            <div className='col-md-6'>
              <Form.Item name={['user', 'FirstName']} label="First Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </div>
            <div className='col-md-6'>
              <Form.Item name={['user', 'LastName']} label="Last Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Form.Item name={['user', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
              </Form.Item>
            </div>
            <div className='col-md-6'>
              <Form.Item
                name={['user', "phone"]}
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>

              <Form.Item name={['user', "DateOfBirth"]} label="Date of birth" rules={[{ type: 'object', required: true }]}>
                <DatePicker className="w-100" format="YYYY-MM-DD" />
              </Form.Item>

            </div>
            <div className='col-md-6 d-none'>
              <Form.Item
                name={['user', "gender"]}
                label="Gender"
                rules={[{ required: true }]}
              >
                <Select placeholder="select your gender">
                  <Option value="Male">Male</Option>
                  <Option selected value="Female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </div>
          </div>


          <Form.Item name={['user', 'introductionToProblem']} label="Description" >
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
            <Button type="primary" htmlType="submit" className='w-25 float-end Custom_bg'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      }

      {Items.length !== 0 && <div>
        <Button type="text" htmlType='button' className='mb-3' onClick={() => { setItems([]) }}> <IoIosArrowBack /> Back </Button>

        <Descriptions title="Session Selected" column={2} items={Items} />
        <Button type="primary" className='w-25 float-end Custom_bg' onClick={() => { ConfirmData() }}>
          Confirm
        </Button>
      </div>}


    </div>
  )
}

export default Ap_Form_Ant

