import React from 'react'
import { Button, Image } from 'react-bootstrap';

// import test_image from "../../images/image.png"

const InnerSlider = (props) => {
  let content = props.data;
  return (
    <div className='position-relative'>

      <div className='row '>
        <div className={`w-50 mt-5 d-none d-md-flex  ${content.order == 12 ? `order-1 justify-content-end fade-in-left` : `order-2 justify-content-start fade-in-right`} align-content-center py-3`}>
          <Image src={content.imgUrl} fluid  />
        </div>
        <div className={` px-5 d-flex ${content.order == 12 ? `order-2 text-start fade-in-right` : `order-1 text-end fade-in-left`} justify-content-center flex-column HeadSlider_Text overflow-hidden `}>
          <div>
            <h1>{content.count}<span>.</span></h1>
            <h1>{content.Head1}</h1>
            <h1>{content.Head2} </h1>
            <h1>{content.Head3}</h1>
            <div className='row'>
              {(content.order!=12)&&<div className='col-md-7'>
                
              </div>}
              <div className='col-6 col-md-4 px-4 d-none'>
                <Button className="btn Prime_btn2 " onClick={() => console.log("Primary")}>
                  Experience
                </Button>
              </div>
              <div className={` col-md-5  px-3`}>
                <Button className="btn Prime_btn" onClick={() => console.log("Primary")}>
                  Dr Fauzia's Expertise
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='position-absolute w-100 bottom-0' style={{ zIndex: "-1" }}>
        <h1 className='WaterPark text-end'>{content.count}<span>.</span></h1>
      </div>
    </div>
  )
}


export default InnerSlider