import React from 'react';
import { Form, Input, DatePicker, Button } from 'antd';
import { Formik } from 'formik';
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required('Please enter your name'),
    phone: yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid phone number format (XXX-XXX-XXXX)'),
    email: yup.string().email('Invalid email format'),
    date: yup.date().required('Please select a date'),
    description: yup.string().optional(), // Optional field
});

const Contact = () => {
    return (
        <div className="container-fluid">
            <div className='row justify-content-center'>
                <div className='col-md-6 text-center'>
                    <p className='doc_des'>Reach for appointment</p>
                    <h1 className='MainHead'>Contact <span>US</span></h1>
                    <p className='doc_des mb-5'>
                        Have questions or need to schedule an appointment? We're here to help! Reach out to us through the following methods and we'll get back to you as soon as possible.
                    </p>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <Formik
                        initialValues={{
                            name: '',
                            phone: '',
                            email: '',
                            date: null,
                            description: '',
                        }}
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => {
                            // Handle form submission with validated values
                            console.log('Submitted values:', values);
                            resetForm();
                        }}
                    >
                        {({ values, handleChange, handleSubmit, errors, touched }) => (
                            <Form layout="vertical">
                                <Form.Item label="Name" required validateStatus={errors.name && touched.name ? "error" : ""}>
                                    <Input name="name" value={values.name} onChange={handleChange} />
                                    {errors.name && touched.name && <span className="error">{errors.name}</span>}
                                </Form.Item>
                                <Form.Item label="Phone" required validateStatus={errors.phone && touched.phone ? "error" : ""}>
                                    <Input name="phone" value={values.phone} onChange={handleChange} />
                                    {errors.phone && touched.phone && <span className="error">{errors.phone}</span>}
                                </Form.Item>
                                <Form.Item label="Email" validateStatus={errors.email && touched.email ? "error" : ""}>
                                    <Input name="email" value={values.email} onChange={handleChange} />
                                    {errors.email && touched.email && <span className="error">{errors.email}</span>}
                                </Form.Item>
                                <Form.Item label="Description">
                                    <Input.TextArea name="description" value={values.description} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className=' w-100' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )

}

export default Contact
