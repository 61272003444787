import React, { useEffect, useState } from 'react'
import RA_Cal from './RA_Cal'
import Sessions from './Sessions'
import { GetAxios } from '../../assets/Globel_Functions/ApiFunction'
import Ap_middle from '../../Forms/BookingForm/Ap_middle'
import { useRecoilValue } from 'recoil'
import { APIBase } from '../../../Atom'

const Booking = () => {

    let DefaultBase = useRecoilValue(APIBase);

    let [dateSelected, setDate] = useState(() => {
        let currentDate = new Date();
        // Extract individual components using destructuring and formatting methods
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
        const day = currentDate.getDate();

        // Format the month with leading zeros if necessary
        const formattedMonth = month.toString().padStart(2, '0');


        return (`${formattedMonth}/${day}/${year}`)

    });
    let [SessionSelected, setSessionSelected] = useState(true);
    let [Session, setSession] = useState([]);
    let [DataFound, setDataFound] = useState([]);


    useEffect(() => {

        fetchApi();

    }, [])

    async function fetchApi() {

        let get_Result = await GetAxios(`${DefaultBase}/api/user_Appointment`);
        if (get_Result) {
            console.log(JSON.stringify(get_Result));
            sessionStorage.setItem("Appointment", JSON.stringify(get_Result));
            setDataFound(get_Result);
        }
    }

    return (
        <div>
            <div className='container mt-5'>
                <div className='row justify-content-center align-content-center'>
                    <div className='col-md-8 text-center'>
                        <p className='doc_des' onClick={() => { console.log(Session) }}>visit clinic to meet for</p>
                        <h1 className='MainHead'>Women's Health <span>Expertise</span></h1>
                        <p className='doc_des'>Get expert advice and personalized care from Dr.Fauzia Anjum, our highly-rated gynecologist. Dr.Fauzia Anjum is dedicated to your well-being and women's health needs.</p>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <h5 id='Health' className='text-center MainHead my-2' style={{ fontSize: "27px" }}>Appointment <span>Booking Form</span></h5>
                        {/* <Calendar />   */}
                        {SessionSelected ?
                            <div className='row justify-content-center gap-4 gap-md-0'>
                                <div className='col-md-5'>
                                    <RA_Cal SetDate={setDate} dbDates={DataFound} />
                                </div>
                                <div className='col-md-5'>
                                    <Sessions SelectedDate={dateSelected} dbDates={DataFound} HandleSelect={setSessionSelected} sessionSelect={setSession} />
                                </div>
                            </div>
                            :
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Ap_middle  Data={Session} SwitchBack={setSessionSelected} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Booking
