import React from 'react'
import { Image } from 'react-bootstrap'
import Sonogram from '../../images/img-01-4.png'
const AllPortals = () => {
    return (
        <div className='container-fluid bg-blue mt-5'>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-6 justify-content-center my-4'>
                        <div className='d-flex w-100 justify-content-center align-items-center rounded overflow-hidden'>
                            <Image src={Sonogram} fluid className='w-100' style={{ "transform": "scale(1)" }} />
                        </div>
                    </div>
                    <div className='col-md-6 my-4'>
                        <div className='d-flex flex-column w-100 justify-content-center gap-3 align-items-center h-100 '>
                            <h5 className='text-left MainHead'>Get Care Anytime, <br /> <span style={{ "fontWeight": "400" }}>Anywhere on Any Device</span></h5>
                            <p className='doc_des ps-md-4'>Get expert gynecological care from the comfort of your own device with my new web app! Designed for accessibility on smartphones, tablets, and computers, it allows you to manage your health conveniently, whenever you need it. Schedule appointments, ask questions, and access valuable resources – all in one secure and user-friendly platform.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPortals
