import React, { useState } from 'react'

import "./style.css"
import HeaderSlider from '../HeaderSlider/HeaderSlider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

//images

function Pre() {
  return (<div><span> <span className='ic'><FontAwesomeIcon icon={faArrowLeft} size='lg' /></span>  <span className='p-0 m-0'>PR</span><span className="d-block p-0 m-0 ">EV</span></span></div>);
}

function Next() {
  return (<div><span><span className='p-0 m-0'>NE</span><span className="d-block p-0 m-0 ">XT</span> <span className='ic'><FontAwesomeIcon icon={faArrowRight} size='lg' /></span></span></div>);
}





const Head = () => {

  let [slideItem, setSliderItem] = useState(0);

  let BackSlide = () => {
    if (slideItem == 0) {
      setSliderItem(3);
      return false
    }
    setSliderItem(slideItem - 1);
  }

  let NextSlide = () => {
    if (slideItem == 3) {
      setSliderItem(0);
      return false
    }
    setSliderItem(slideItem + 1);
  }

  return (
    <div>
      <header className='position-relative'>
        <HeaderSlider sliderNumber={slideItem} />
        <div className='PreBlock' onClick={() => { BackSlide(); }}><Pre /></div>
        <div className='NextBlock' onClick={() => { NextSlide(); }}><Next /></div>
      </header>
    </div>
  )
}

export default Head