import React from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// images
// import logo_img from "../images/ack-removebg-preview.png"
import logo_img from "../images/ack.png"
import { Image } from 'react-bootstrap';
import Account from './Account';
import { Link } from 'react-scroll';

const NevBar = () => {

  return (
    <Navbar expand="lg" className="bg-body-tertiary ">
      <Container className='p-0'>
        <Navbar.Brand href="#home" className='ms-3 ms-md-0' ><Image src={logo_img} fluid className='BrandImage' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav " >
          <Nav className="NevMenu">
            <Nav.Link href="#home">Main</Nav.Link>
            <Link to="Health" smooth={true} duration={500} spy={true} offset={-50}><Nav.Link href="#Health" >Appointment</Nav.Link></Link>

            <NavDropdown title="About" id="basic-nav-dropdown">
              <Link to="Info" smooth={true} duration={500} spy={true} offset={-50}>
                <NavDropdown.Item href="#action/3.2">
                  Info
                </NavDropdown.Item>
              </Link>
              <Link to="Experience" smooth={true} duration={500} spy={true} offset={-50}>
                <NavDropdown.Item href="#action/3.1" >Experience</NavDropdown.Item>
              </Link>
              <NavDropdown.Item href="#action/3.3">Reviews</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                Comments
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#Contact">Contact</Nav.Link>
            <Nav.Link href="#Pharmacy">FAQs</Nav.Link>
          </Nav>
          <div className="unwanted d-none">
            <Account />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NevBar