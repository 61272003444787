import React from 'react'
import Head from './Head/Head'
import Docinfo from './Docinfo/Docinfo'
import Steps from './Steps/Steps'
import Booking from './Booking/Booking'
import AllPortals from './AllPortals/AllPortals'
import Experience from './Experience/Experience'
import Last_Base from './Last_Base/Last_Base'
import Contact from './Contact/Contact'

const LandingPage = () => {
  return (
    <div>
      <Head />
      <Docinfo />
      <Steps />
      <Booking />
      <AllPortals />
      <Experience />
      <Last_Base />
      <Contact />
    </div>
  )
}

export default LandingPage
