
// import React, { useEffect, useState } from 'react';


// import { Button } from 'antd';

// const Sessions = (props) => {
//     const [dateFound, setDateFound] = useState(false);
//     const [bookedSessions, setBookedSessions] = useState([]);

//     // Filter out booked sessions for the selected date
//     useEffect(() => {
//         if (props.dbDates.length > 0) {
//             const selectedDateSessions = props.dbDates.filter(session => session.sessionDate === props.SelectedDate);
//             setBookedSessions(selectedDateSessions);
//         }
//     }, [props.dbDates, props.SelectedDate]);

//     const isSessionBooked = (sessionTime) => {
//         return bookedSessions.some(session => session.sessionTime === sessionTime);
//     };

//     const SessionHandle = (sessionTime) => {
//         props.HandleSelect(false);
//         props.sessionSelect([{ DateSelected: props.SelectedDate, SessionSelected: sessionTime }]);
//     };

//     // Generate available session buttons
//     const availableSessions = () => {
//         let first = 14; // 2:00 pm
//         let second = 17; // 5:00 pm
//         const totalSessions = (second - first) * 4; // 4 sessions per hour
//         const sessions = [];

//         for (let i = 0; i < totalSessions; i++) {
//             const sessionHour = Math.floor(i / 4) + first - 12;
//             const sessionMinute = (i % 4) * 15;
//             const sessionTime = `${sessionHour}:${sessionMinute.toString().padStart(2, '0')}`;

//             if (!isSessionBooked(sessionTime)) {
//                 sessions.push(
//                     <Button
//                         key={sessionTime}
//                         type="dashed"
//                         className={`m-2 w-25`}
//                         data-aos="fade-left" data-aos-duration="3000"
//                         onClick={() => SessionHandle(sessionTime)}
//                     >
//                         {sessionTime}
//                     </Button>
//                 );
//             }
//         }

//         return sessions;
//     };

//     return (
//         <div>
//             <h4 className='mb-3 fs-17 Custom_color'>Sessions of day {props.SelectedDate}</h4>
//             {availableSessions()}
//         </div>
//     );
// };

// export default Sessions;




// import React, { useEffect, useState } from 'react';
// import { Button } from 'antd';
// import Thinkingillustr from './Thinkingillustr/Thinkingillustr';

// const Sessions = (props) => {
//     const [bookedSessions, setBookedSessions] = useState([]);

//     // Filter out booked sessions for the selected date
//     useEffect(() => {
//         if (props.dbDates.length > 0) {
//             const selectedDateSessions = props.dbDates.filter(session => session.sessionDate === props.SelectedDate);
//             setBookedSessions(selectedDateSessions);
//         }
//     }, [props.dbDates, props.SelectedDate]);

//     const isSessionBooked = (sessionTime) => {
//         return bookedSessions.some(session => session.sessionTime === sessionTime);
//     };

//     const SessionHandle = (sessionTime) => {
//         props.HandleSelect(false);
//         props.sessionSelect([{ DateSelected: props.SelectedDate, SessionSelected: sessionTime }]);
//     };

//     // Check if the current date and selected date are the same
//     const isSameDay = (date1, date2) => {
//         return date1.getFullYear() === date2.getFullYear() &&
//             date1.getMonth() === date2.getMonth() &&
//             date1.getDate() === date2.getDate();
//     };

//     // Get the current date and time
//     const currentDate = new Date();
//     const currentHour = currentDate.getHours();

//     // Generate available session buttons
//     const availableSessions = () => {
//         let first = 14; // 2:00 pm
//         let second = 17; // 5:00 pm
//         const totalSessions = (second - first) * 4; // 4 sessions per hour
//         const sessions = [];

//         for (let i = 0; i < totalSessions; i++) {
//             const sessionHour = Math.floor(i / 4) + first - 12;
//             const sessionMinute = (i % 4) * 15;
//             const sessionTime = `${sessionHour}:${sessionMinute.toString().padStart(2, '0')}`;

//             // Skip sessions between 2:00 pm and 3:00 pm if the current time is past 1:00 pm
//             if (isSameDay(currentDate, new Date(props.SelectedDate))) {
//                 if ((currentHour >= 13 && sessionHour === 2) || (currentHour >= 14 && sessionHour === 3) || (currentHour >= 14 && sessionHour === 4)) {
//                     continue;
//                 }
//             }

//             if (!isSessionBooked(sessionTime)) {
//                 sessions.push(
//                     <Button
//                         key={sessionTime}
//                         type="dashed"
//                         className={`m-2 w-25`}
//                         data-aos="fade-left" data-aos-duration="3000"
//                         onClick={() => SessionHandle(sessionTime)}
//                     >
//                         {sessionTime}
//                     </Button>
//                 );
//             }
//         }

//         return sessions.length > 0 ? sessions : (<div className="row justify-content-center ">

//             <Thinkingillustr />

//         </div>)
//     };

//     return (
//         <div>
//             <h4 className='mb-3 fs-17 Custom_color'>Sessions of day {props.SelectedDate}</h4>
//             {availableSessions()}
//         </div>
//     );
// };

// export default Sessions;

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Thinkingillustr from './Thinkingillustr/Thinkingillustr';

const Sessions = (props) => {
    const [bookedSessions, setBookedSessions] = useState([]);

    // Filter out booked sessions for the selected date
    useEffect(() => {
        if (props.dbDates.length > 0) {
            const selectedDateSessions = props.dbDates.filter(session => session.sessionDate === props.SelectedDate);
            setBookedSessions(selectedDateSessions);
        }
    }, [props.dbDates, props.SelectedDate]);

    const isSessionBooked = (sessionTime) => {
        return bookedSessions.some(session => session.sessionTime === sessionTime);
    };

    const SessionHandle = (sessionTime) => {
        props.HandleSelect(false);
        props.sessionSelect([{ DateSelected: props.SelectedDate, SessionSelected: sessionTime }]);
    };

    // Get the current date and selected date
    const currentDate = new Date();
    const selectedDate = new Date(props.SelectedDate);
    const selectedDay = selectedDate.getDay();

    // Define meeting times based on the day of the week
    const meetingTimes = {
        0: { morning: [], evening: [] }, // Sunday
        1: { morning: [], evening: ['18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30'] }, // Monday
        2: { morning: ['11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00'], evening: [] }, // Tuesday
        3: { morning: ['11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00'], evening: ['18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30'] }, // Wednesday
        4: { morning: [], evening: [] }, // Thursday
        5: { morning: [], evening: ['18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30'] }, // Friday
        6: { morning: ['11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00'], evening: [] } // Saturday
    };
       // Convert time to 12-hour format with AM/PM
       const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${adjustedHour}:${minute.toString().padStart(2, '0')} ${period}`;
    };

    // Combine morning and evening sessions based on the selected day
    const getAvailableSessions = () => {
        const sessions = [];
        const { morning, evening } = meetingTimes[selectedDay];

        const addSessions = (times) => {
            times.forEach(sessionTime => {
                if (!isSessionBooked(sessionTime)) {
                    sessions.push(
                        <Button
                            key={sessionTime}
                            type="dashed"
                            className={`m-2 w-25`}
                            data-aos="fade-left" data-aos-duration="3000"
                            onClick={() => SessionHandle(sessionTime)}
                        >
                            {convertTo12HourFormat(sessionTime)}
                        </Button>
                    );
                }
            });
        };

        // Skip morning sessions if it's past the morning time on the current day
        if (!isSameDay(currentDate, selectedDate) || currentDate.getHours() < 11) {
            addSessions(morning);
        }

        // Skip evening sessions if it's past the evening time on the current day
        if (!isSameDay(currentDate, selectedDate) || currentDate.getHours() < 18) {
            addSessions(evening);
        }

        // return sessions;

        return sessions.length > 0 ? sessions : (<div className="row justify-content-center ">

                   <Thinkingillustr />
            
                     </div>)
    };

    // Check if the current date and selected date are the same
    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };

    return (
        <div>
            <h4 className='mb-3 fs-17 Custom_color'>Sessions of day {props.SelectedDate}</h4>
            {getAvailableSessions()}
        </div>
    );
};

export default Sessions;
