import React from 'react'
import { Image } from 'react-bootstrap'

import doc from "../../images/orignal.jpg"

const Docinfo = () => {
  return (
    <div className='position-relative doc_img_com mb-5'>
      <div className='container mt-5'>
        <div className='row '>
          <div className='col-md-6 order-2 order-md-1 px-4' >
            <div className='d-flex flex-column justify-content-center align-content-center gap-1 h-100'>
              <h1 id='Info' className='MainHead'>Dr. Fauzia Anjum</h1>
              <h3 className='SubHead'>Obstetrics & Gynaecology</h3>
              <p className='doc_des'>Dr Fauzia Anjum is the leading Gynaecologist in Lahore with over 20 years of experience. She trained in Hameed Latif Hospital and then went on to acquire advanced qualifications from France,  Germany and UAE especially focused on Laparoscopic surgeries for gynaecological diseases. She also gained experience at Birmingham Women's Hospital Birmingham, UK.
                Dr Fauzia Anjum has been performing all types of conventional as well as Laparoscopic surgeries (key hole surgery for treatment of fibroids, removal of uterus, tubal surgeries, ovarian cysts, adhesiolysis and all other types of laparoscopic surgeries) and her patients are extremely satisfied with her professionalism, ethics,  individual care & attention and empathy.</p>
              <div className='d-flex  justify-content-start align-content-center gap-4  d-none'>
                <button type="submit" className='btn Prime_btn'> About Us </button>
                <button type="submit" className='btn Prime_btn2'> Contact </button>
              </div>
            </div>
          </div>
          <div className='col-md-6 order-1 order-md-2 my-5 my-md-0'>
            <div className='d-flex justify-content-center align-content-center position-relative'>
              <div className='doc_img position-relative'>
                <Image src={doc} fluid style={{ height: "30rem" }} />
              </div>
              <div className='position-absolute end-0 bottom-0 doc_Name '>
                <div className='position-relative'>
                  <h3><strong>Dr. Fauzia Anjum</strong></h3>
                  <h6>Gynaecological Surgery & Obstetrics </h6>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Docinfo