import React from 'react'
import { Card, Image } from 'react-bootstrap';

import doc2 from "../../images/Aunti1.png"
import operationIcon from "../../images/doc-icon.svg"
import operationIcon2 from "../../images/doc-icon2.svg"
import Slider from '../Slider/Slider';

const Last_Base = () => {

    let Skills = [
        {
            Title: "All types of lower segment c-sections",
            icon: operationIcon,
            Description:"Lower segment C-sections involve a horizontal incision in the lower abdomen and uterus for childbirth."
        },
        {
            Title: "Obstetric Hysterectomy",
            icon: operationIcon,
            Description:"Obstetric hysterectomy is the surgical removal of the uterus during pregnancy or shortly after childbirth."
        },
        {
            Title: "Repair of vaginal & cervical tears / Haematomas",
            icon: operationIcon,
            Description:"Obstetric hysterectomy is the surgical removal of the uterus during pregnancy or shortly after childbirth."
        },
        {
            Title: "Instrumental deliveries",
            icon: operationIcon,
            Description:"Obstetric hysterectomy is the surgical removal of the uterus during pregnancy or shortly after childbirth."
        },
        {
            Title: "CVS (assisted only) & Amniocentesis",
            icon: operationIcon2,
            Description:"Obstetric hysterectomy is the surgical removal of the uterus during pregnancy or shortly after childbirth."
        },
        {
            Title: "Total Abdominal Hysterectomy",
            icon: operationIcon2,
            Description:"A total abdominal hysterectomy is the surgical removal of the entire uterus and cervix through an incision in the lower abdomen."
        },
    ]

    return (
        <div className='container-fluid bg-blue my-5 pt-5'>
            <div className='row'>
                <div className='col-md-12 text-center px-5'>
                    <p className='doc_des'>Clinical Proficiency</p>
                    <h1 className='MainHead'>Skills and  <span>abilities</span></h1>
                    <p className='doc_des custom_Padding' >I have been practicing Obstetrics & Gynaecology since Apr 2005. During this time I have performed all major and minor procedures independently. A brief breakdown of the cases is given below
                    </p>
                </div>
            </div>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-4'>
                        <Image src={doc2} fluid style={{ height: "30rem" }} />
                    </div>
                    <div className='col-md-8' style={{margin:"4rem 0rem 0rem 0rem"}}>
                        <Slider Data={Skills} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Last_Base
