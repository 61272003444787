import React from 'react'
import { Image } from 'react-bootstrap'

import WebPostal from '../../images/img-01-3.png'
import BookAppointment from '../../images/img-02-4.png'
import feedBack from '../../images/img-03-3.png'
import { Link } from 'react-scroll'

const Steps = () => {
    return (
        <div className='Steps-com'>
            <div className='container'>
                <div className='row justify-content-center align-content-center'>
                    <div className='col-md-8 text-center'>
                        <p className='doc_des'>We Made It Simple</p>
                        <h1 className='MainHead'>How It <span>Works?</span></h1>
                        <p className='doc_des'>To Schedule an appointment select available time Slot, Fill the form, Review Information, You will receive an appointment confirmation via message or email.
                        </p>
                    </div>
                </div>
                <div className='Steps_flex mt-5'>
                    <Link to="Health" smooth={true} duration={500} spy={true} offset={-50} className='cursor-pointer text-decoration-none'>
                        <div className='text-center StepHead'>
                            <div className='p-3 rounded-circle imgP'>
                                <Image src={WebPostal} fluid className='rounded-circle p-5 bg-light shadow' />
                            </div>
                            <p className='doc_des mt-3'>Choose Your</p>
                            <h5>Appointment</h5>
                        </div>
                    </Link>
                    <Link to="Health" smooth={true} duration={500} spy={true} offset={-50} className='cursor-pointer text-decoration-none'>
                        <div className='blockImg text-center StepHead'>
                            <div className='p-3 rounded-circle imgP'>
                                <Image src={BookAppointment} fluid className='rounded-circle p-5 bg-light shadow' />
                            </div>
                            <p className='doc_des mt-3'>Fill Out The</p>
                            <h5>Form</h5>
                        </div>
                    </Link>
                    <Link to="Health" smooth={true} duration={500} spy={true} offset={-50} className='cursor-pointer text-decoration-none'>
                        <div className='text-center StepHead'>
                            <div className='p-3 rounded-circle imgP'>
                                <Image src={feedBack} fluid className='rounded-circle p-5 bg-light shadow' />
                            </div>
                            <p className='doc_des mt-3'>Confirm and Receive</p>
                            <h5>Your Details</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Steps