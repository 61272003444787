import React from 'react'
// import Slider from '../Slider/Slider'
import Card_IMG from '../../images/hameed-latif.jpeg'
import Card_IMG2 from '../../images/download.png'
import ExperienceBlocks from './ExperienceBlocks'

const Experience = () => {

    let sliderData = [
        {
            Title: "Assistant Professor of Obstetrics & Gynaecology",
            Description: "Rashid Latif Medical College and Consultant Gynaecologist at Hameed Latif Hospital May 15, 2013 to 4 Dec 2019",
            Location_img: Card_IMG,
            Logo_img: Card_IMG2,
            CompanyName: "Hameed Latif Hospital",
            Start_Date: "23/07/2023",
            Currently_Working: true,
        },
        {
            Title: "Assistant Professor of Obstetrics & Gynaecology",
            Description: "Rashid Latif Medical College and Consultant Gynaecologist at Hameed Latif Hospital May 15, 2013 to 4 Dec 2019",
            Location_img: Card_IMG,
            Logo_img: Card_IMG2,
            CompanyName: "Hameed Latif Hospital",
            Start_Date: "23/07/2023",
            Currently_Working: false,
        },
        {
            Title: "Assistant Professor of Obstetrics & Gynaecology",
            Description: "Rashid Latif Medical College and Consultant Gynaecologist at Hameed Latif Hospital May 15, 2013 to 4 Dec 2019",
            Location_img: Card_IMG,
            Logo_img: Card_IMG2,
            CompanyName: "Hameed Latif Hospital",
            Start_Date: "23/07/2023",
            Currently_Working: false,
        },
        {
            Title: "Assistant Professor of Obstetrics & Gynaecology",
            Description: "Rashid Latif Medical College and Consultant Gynaecologist at Hameed Latif Hospital May 15, 2013 to 4 Dec 2019",
            Location_img: Card_IMG,
            Logo_img: Card_IMG2,
            CompanyName: "Hameed Latif Hospital",
            Start_Date: "23/07/2023",
            Currently_Working: true,
        },
        {
            Title: "Assistant Professor of Obstetrics & Gynaecology",
            Description: "Rashid Latif Medical College and Consultant Gynaecologist at Hameed Latif Hospital May 15, 2013 to 4 Dec 2019",
            Location_img: Card_IMG,
            Logo_img: Card_IMG2,
            CompanyName: "Hameed Latif Hospital",
            Start_Date: "23/07/2023",
            Currently_Working: false,
        },
    ]
    return (
        <div>
            <div className='container mt-5'>
                <div className='row justify-content-center align-content-center'>
                    <div className='col-md-8 text-center'>
                        <p className='doc_des'>Serving Women at All Stages of Life</p>
                        <h1 className='MainHead'>Specializing in Women's <span>Health</span></h1>
                        <p className='doc_des'>Dr. Fauzia Anjum is a dedicated gynecologist passionate about women's health and well-being. With a focus on preventive care, personalized treatment plans, and patient education, she empowers women to make informed decisions about their health. Whether you're seeking routine checkups, addressing specific concerns, or navigating life transitions, Dr. Fauzia Anjum is here to provide comprehensive and compassionate care you can trust.</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid' id='Experience'>
                {/** <Slider Data={sliderData} /> */}
                <ExperienceBlocks Data={sliderData} />
            </div>
        </div>
    )
}

export default Experience
