import React from 'react'
import { Button, Image } from 'react-bootstrap';

import acc_Img from "../images/acc_Img.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';



const Account = props => {
  return (
    <div className='p-0 m-0 d-none'>
      <div className='account_Flex'>
        {sessionStorage.getItem("account_role") === "admin" ? <div className='cart Added'>
          <FontAwesomeIcon icon={faCartPlus} size='xl' />
        </div>
          : ""}
        {sessionStorage.getItem("account_role") === "admin" ? <div className='border account_image'>
          <Image src={acc_Img} fluid />
        </div>
          : ""}
        <div>
          {sessionStorage.getItem("account_role") === "admin" ? "" : <Button variant="outline-primary" style={{width:"8rem"}} className={` btn_primary mx-2 `} onClick={() => console.log("Join_btn")}>
            Sign-In
          </Button>}

          <Button variant="outline-primary" className={`Join_btn`} onClick={() => console.log("Join_btn")}>
            Join Now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Account