import React from 'react'
import Ap_Form_Ant from './Ap_Form_Ant'
import doc from "../../images/orignal.jpg"
import { Image } from 'react-bootstrap'

const Ap_middle = (props) => {
    return (
        <div className='row'>
            <div className='col-md-6'>
                <Ap_Form_Ant Data={props.Data} SwitchBack={props.SwitchBack} />
            </div>
            <div className='col-md-6 d-none d-md-inline-block'>
                <div className='d-flex justify-content-center align-content-center position-relative'>
                    <div className='doc_img position-relative'>
                        <Image src={doc} fluid style={{height:"30rem"}} />
                    </div>
                    <div className='position-absolute end-0 bottom-0 doc_Name '>
                        <div className='position-relative'>
                            <h3><strong>Dr. Fauzia Anjum</strong></h3>
                            <h6>Gynaecological Surgery & Obstetrics </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ap_middle
