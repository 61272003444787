import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
// import TopBar from "./Components/TopBar/TopBar";
import LandingPage from "./Components/LandingPage/LandingPage";
import NevBar from "./Components/NevBar/NevBar";
import Footer from "./Components/Footer/Footer";
import { useEffect } from "react";
// Animation package
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 100,
      disable: "mobile",
    });
  }, []);
  return (
    <div className="container-fluid p-0 m-0">

      {/*<TopBar/> this is also a nev bar but i don't created it bootstrap navbar*/}
      <NevBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <div className="DocTrack_Footer">
        <Footer />
      </div>

    </div>
  );
}

export default App;
