import React, { useEffect, useState } from 'react';
import { Calendar, theme } from 'antd';

const RA_Cal = (props) => {

  const { token } = theme.useToken();



  const onPanelChange = (value, mode) => {
    console.log(value, mode);
    // props.SetDate(value)
  };

  const handleDateClick = (value, mode) => {
    // console.log(value.format('MM/DD/YYYY'), mode);
    props.SetDate(value.format('MM/DD/YYYY'));
  };

  const wrapperStyle = {
    width: "100%",
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const [bookedDates, setBookDates] = useState(['2024-02-26', '2024-01-10', '2024-01-15']);
  const meetingTimes = [
    '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45',
    '04:00', '04:15', '04:30'
  ];
  const meetingDuration = 15; // minutes

  useEffect(() => {
    if (props.dbData) {
      const sessionDates = props.dbData.map(({ sessionDate, sessionTime }) => sessionDate);
      const updatedBookedDates = sessionDates.reduce((acc, currDate) => {
        // Check if all appointment times are booked for the current date
        const isFullyBooked = meetingTimes.every(time =>
          props.dbData.some(session =>
            session.sessionDate === currDate &&
            time === session.sessionTime
          )
        );

        if (isFullyBooked) {
          // Add the date to bookedDates if all times are booked
          acc.push(currDate);
        }

        return acc;
      }, [...bookedDates]); // Preserve existing booked dates

      setBookDates(updatedBookedDates);
    }
  }, [props.dbData]);



  const disabledDate = current => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set time to midnight for accurate comparison

    const isBeforeToday = current && current < today;

    const isBookedDate = bookedDates.some(bookedDate => {
      const bookedDateTime = new Date(bookedDate);
      bookedDateTime.setHours(24, 24, 24, 24); // Set time to midnight for accurate comparison
      return current && current.format('YYYY-MM-DD') === bookedDateTime.toISOString().split('T')[0];
    });

    // Check if the current date is a Sunday
    const isSunday = current && current.day() === 0; // Sunday has index 0


    // console.log(current);


    // Get the date 30 days from now
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(today.getDate() + 30);

    // Check if the current date is after 30 days from today
    const isAfterThirtyDays = current && current > thirtyDaysFromNow;

    // const todayNow = new Date();


    return isBeforeToday || isBookedDate || isSunday || isAfterThirtyDays ;
  };





  return (
    <div style={wrapperStyle}>
      <Calendar fullscreen={false} onPanelChange={onPanelChange} disabledDate={disabledDate} onSelect={handleDateClick} />
    </div>
  )
}

export default RA_Cal


