import React from 'react'
import img1 from '../../images/hameed-latif.jpeg'

import SidebySideBlock from './SidebySideBlock/SidebySideBlock';


const ExperienceBlocks = () => {

  let obj = [
    {
      img: img1,
      para: "Dr Fauzia Anjum is an associate professor in Obstetrics & Gynecology at Rashid Latif Medical College. She has over 15 years experience as a consultant Gynecologist and provides healthcare services to women of all age groups .  Her clinic is located in <a href='https://maps.app.goo.gl/cQPTjCKVBy6DnhzD6'>Hameed Latif hospital Lahore</a> which is the leading hospital for gynecological treatment.",
      at: "Rashid Latif Medical College (5 Dec 2019 to date)",
      position: "Associate Professor of Obstetrics & Gynaecology",
      extra: "flex-column-reverse  flex-md-row",
      servicesTitle: "Services include :",
      services: ["Painless Delivery.", "Caesarean sections", "Diagnostic laparoscopy", "Laparoscopic treatment for a variety of conditions like ectopic pregnancy , fibroids , hysterectomy and ovarian cyst", "Infertility evaluation & treatment .", "Pre-conception advice", "Well women clinic", "Antenatal care", "Polycystic ovary syndrome" ,"Menopuasal problems"]
    },
    {
      img: img1,
      para: "As an assistant professor in Obstetrics & Gynaecology at Rashid Latif Medical College Lahore and a consultant at Hameed Latif Hospital, I taught and supervised both undergraduate and postgraduate students. My clinical responsibilities included patient care in labor rooms and wards, conducting surgeries such as Caesarean sections, myomectomies, and hysterectomies, and performing diagnostic and operative laparoscopic and hysteroscopic procedures, totaling over 1500 surgeries.",
      at: "Rashid Latif Medical College (May 15, 2013 to 4 Dec 2019)",
      position: "Assistant Professor of Obstetrics & Gynaecology",
      extra: "flex-column-reverse  flex-md-row-reverse mt-5"
    },
    {
      img: img1,
      para: "I worked as a consultant in Obstetrics and Gynaecology in Hameed Latif Hospital Lahore Pakistan during this tenure and was consultant in-charge of the labour room for more than a year. I performed all types of deliveries including instrumental deliveries during this time. I attended outpatients twice a week and worked in the theatre twice a week. I performed all conventional procedures during this time including Caesarean sections, laparotomies, diagnostic laparoscopies, Marsupialisation, D&C, polypectomy, cervical cautery and colpo-perineorrhaphy.",
      at: "Hameed Latif Hospital, Lahore (September, 2009 to May, 2013)",
      position: "Consultant in Obstetrics and Gynaecology",
      extra: "flex-column-reverse  flex-md-row mt-5"
    },
    {
      img: img1,
      para: "This was my training period wherein I learned my Obstetrics & Gynaecology. I worked as resident on-call in 1 in 4 rota and went through rotations in gynaecology ward, obstetrics ward, labour room and gynaecological oncology. I did more than 200 vaginal deliveries including instrumental deliveries and repair of vaginal and cervical tears and also assisted chorionic villous sampling and amniocentesis.",
      at: "Hameed Latif Hospital, Lahore (23 Apr 2005 to 23 Apr 2009)",
      position: "Registrar in Obstetrics & Gynaecology",
      extra: " flex-column-reverse  flex-md-row-reverse mt-5"
    },
  ]


  return (
    <div>
      {obj.map((elem, index) => {

        return (<SidebySideBlock data={elem} />)
      })}
    </div>
  )
}

export default ExperienceBlocks
